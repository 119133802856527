import { Row, Col, Container } from "react-bootstrap";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { HiMail } from "react-icons/hi";

const FindMe = () => {
  return (
    <Container>
      <Row>
        <Col md={12} className="home-about-social">
          <h1>FIND ME ON</h1>
          <p>
            Feel free to <span className="purple">connect </span>with me
          </p>
          <ul className="home-about-social-links">
            <li className="social-icons">
              <a
                href={process.env.REACT_APP_TWITTER}
                target="_blank"
                rel="noopener noreferrer"
                className="icon-colour home-social-icons"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href={process.env.REACT_APP_INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
                className="icon-colour home-social-icons"
              >
                <AiFillInstagram />
              </a>
            </li>
            <li className="social-icons">
              <a
                href={`mailto:${process.env.REACT_APP_EMAIL}`}
                target="_blank"
                rel="noopener noreferrer"
                className="icon-colour home-social-icons"
              >
                <HiMail />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default FindMe;
