import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../assets/about/avatar.svg";
import Tilt from "react-parallax-tilt";

const Introduction = () => {
  // const beHappy = true;
  // function code() {}

  //     // A positive attitude will lead to positive outcomes

  //                       while (beHappy) {
  //                         code();
  //                       }

  return (
    <Container className="home-about-section">
      <Row>
        <Col md={8} className="home-about-description">
          <h1 style={{ fontSize: "2.6em" }}>
            LET ME <span className="purple"> INTRODUCE </span> MYSELF
          </h1>

          <p className="home-about-body">
            <b className="purple">Engineer </b>
            with{" "}
            <i>
              <b className="purple">bachelor’s degree </b>{" "}
            </i>{" "}
            and{" "}
            <i>
              <b className="purple">three years </b>{" "}
            </i>
            of performing in Web and Mobile development.
            <br />
            <br />I am fluent in classics like
            <i>
              <b className="purple"> React and GraphQL.</b>
            </i>
            <br />
            <br />
            My field of interests are building new&nbsp;
            <i>
              <b className="purple">Exciting Web and Mobile Products</b> and
              plan
              <br />
              <b className="purple">Digital Marketing Campaings.</b>
            </i>
            <br />
            <br />
            Whenever possible, I also apply my passion for developing products
            with{" "}
            <i>
              <b className="purple">Next.js, React.js, GraphQL</b>
            </i>{" "}
            and
            <i>
              <b className="purple"> Node.js</b>
            </i>
          </p>
        </Col>
        <Col md={4} className="myAvtar">
          <Tilt>
            <img src={myImg} className="img-fluid" alt="avatar" />
          </Tilt>
        </Col>
      </Row>
    </Container>
  );
};
export default Introduction;
