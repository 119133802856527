import Carousel from "react-bootstrap/Carousel";
import path from "../../assets/about/path.jpeg";
import visualBasic from "../../assets/about/visual-basic.jpeg";
import windingRoad from "../../assets/about/winding-road.jpeg";
import engineering from "../../assets/about/engineering.jpeg";
import programming from "../../assets/about/programming.jpeg";
import about from "../../assets/about/about.png";
import thankyou from "../../assets/about/thankyou.jpeg";

const CarouselStory = () => {
  return (
    <Carousel fade interval={999999}>
      <Carousel.Item>
        <img className="carousel-img" src={path} alt="Second slide" />
        <Carousel.Caption>
          <p>
            The journey started when I was 12 years old, with an old Visual
            Basic book in my parent's house...
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="carousel-gradient">
        <img className="carousel-img" src={visualBasic} alt="Second slide" />
        <Carousel.Caption>
          <p>
            Something about that red cover and the mistery behing how computers
            work caught my attention.
          </p>
          <p>
            I fell in love with coding, and during the next years I developed
            serveral projects.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="carousel-gradient">
        <img className="carousel-img" src={windingRoad} alt="Second slide" />
        <Carousel.Caption>
          <p>
            Unfortunately I was alone. I did everything by myself without a
            formal education.
          </p>
          <p>
            Learning to code at that age, without a tutor, without good
            educational resources, and a barely new internet connection was
            tough. It discouraged me from pursuing a programming career.
          </p>
          <p>The road became winding. But not for much longer...</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="carousel-gradient">
        <img className="carousel-img" src={engineering} alt="Third slide" />
        <Carousel.Caption>
          <p>
            At the age of 17 I began to study engineering and working. Both
            became a constant for several years. And the difficulties I had
            before didn't discourage me to keep using my learned coding
            abilities during my professional career and university studies.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="carousel-gradient">
        <img className="carousel-img" src={programming} alt="Third slide" />
        <Carousel.Caption>
          <p>
            After many years of studying and working hard I became an engineer
            and got a BS degree.
          </p>
          <p>
            A few years later I decided to return to the programming path and I
            dedicated myself exclusively to it. I took many courses and got my
            first IT job.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="carousel-gradient">
        <img
          className="carousel-img"
          src={about}
          alt="Third slide"
          style={{ visibility: "hidden" }}
        />
        <Carousel.Caption>
          <h4>And since then...?</h4>
          <br />
          <p>I contributed to develop:</p>
          <br />
          <ul style={{ listStyleType: "none", fontSize: "1.1rem", padding: 0 }}>
            <li>🌎 A social media app.</li>
            <li>💰 A global e-commerce.</li>
            <li>🇺🇸 A management system for the US market.</li>
            <li>🛒 A marketplace for Europe.</li>
            <li>🧠 An app to learn anything faster.</li>
          </ul>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="carousel-gradient">
        <img className="carousel-img" src={thankyou} alt="Third slide" />
        <Carousel.Caption>
          <p>Thanks for taking the time to know more about me.</p>
          <p>Now let's create that amazing project together! 🚀</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselStory;
