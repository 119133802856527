import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import CarouselStory from "./Carousel";

const Story = () => {
  return (
    <Container className="home-about-section">
      <Row>
        <h1 style={{ fontSize: "2.6em" }}>
          The <span className="purple"> Story</span>
        </h1>
        <CarouselStory />
        {/* <p className="home-about-body" style={{ paddingTop: "1rem" }}>
          <p style={{ marginBottom: "3rem" }}>
            The journey started when I was 12 years old, with an old Visual
            Basic book in my parent's house...
          </p>
          <img
            src={visualBasic}
            style={{
              float: "left",
              width: "15rem",
              marginRight: "1rem",
              marginBottom: "1rem",
            }}
            alt="avatar"
          />
          <br />
          <br />
          Something about the red cover of that book and the mistery behing how
          computers work caught my attention.
          <br />
          <br />
          I fell in love with coding, and during the next years I developed
          serveral projects.
          <br />
          <br />
          Unfortunately I was alone. I did everything by myself without a formal
          education. Learn coding at that age, without a tutor, a with a barely
          new internet connection without good educational resources was tough.
          It discouraged me from pursuing a programming career.
          <br />
          <br />
          The path was diverted. But not for much longer...
          <br />
          <br />
          At the age of 17 I began to study engineering and working. Both became a constant for several years.
          And the difficulties I had before didn't discourage me to keep using my learned coding abilities
          during my professional career and university studies 😎.
          <br />
          <br />
          After practicing engineering for a few years I decided to dedicate myself exclusively to programming. I took many courses and got my first IT job.
          <br />
          <br />
          And... what happened since then?
          <br />
          <br />I contributed to develop a social media app, a global payment
          app and a management system for the US market.

          <p>Thanks for taking the time to know more about me.</p>
          <p>Now let's create that amazing project together! 😎</p>
        </p> */}
      </Row>
    </Container>
  );
};

export default Story;
