import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../assets/home-main.svg";
import Particle from "../Particle";
import Type from "./Type";
import { runPreload } from "../../preloads/preloads";
// import { getConnectionData } from "../../utils/getConnectionData";

function Home() {
  useEffect(() => {
    setTimeout(() => {
      runPreload();
    }, 2000);

    // setTimeout(() => {
    // fetch(`${process.env.REACT_APP_BACKEND_URL}/connection`);
    // getData();
    // async function getData() {
    //   await getConnectionData();
    // }
    // }, 4000);
  }, []);

  return (
    <Container fluid className="home-section" id="home">
      <Container className="home-content">
        <Row>
          <Col md={7} className="home-header">
            <h1 style={{ paddingBottom: 15 }} className="heading">
              Hi There!{" "}
              <span className="wave" role="img" aria-labelledby="wave">
                👋🏻
              </span>
            </h1>

            <h1 className="heading-name">
              I'M
              <strong className="main-name"> JUAN SÁNCHEZ</strong>
            </h1>

            <div className="typewriter-container">
              <Type />
            </div>
          </Col>

          <Col md={5} style={{ paddingBottom: 20 }}>
            <img
              src={homeLogo}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px" }}
            />
          </Col>
        </Row>
      </Container>
      <Particle />
    </Container>
  );
}

export default Home;
