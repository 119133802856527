import { useState, useRef, useEffect } from "react";
import { IoSend } from "react-icons/io5";
import { TiTick } from "react-icons/ti";

const SubmitButton = ({ isReadToSubmit, emailSent }) => {
  const [onClick, setOnClick] = useState(false);
  const buttonEl = useRef();

  useEffect(() => {
    setOnClick(false);
  }, [emailSent]);

  return (
    <button
      id="submit-email-btn"
      onClick={() => isReadToSubmit && setOnClick(true)}
      ref={buttonEl}
      type="submit"
      className={`submit-email-btn center-aligned ${
        onClick ? "submit-email-btn-circular submit-email-btn-onclick" : ""
      } ${
        emailSent ? "submit-email-btn-circular submit-email-btn-validate" : ""
      }`}
    >
      Send{" "}
      {!emailSent ? (
        <IoSend style={{ marginLeft: "0.5rem" }} />
      ) : (
        <TiTick style={{ fontSize: "2rem", color: "#623686" }} />
      )}
    </button>
  );
};

export default SubmitButton;
