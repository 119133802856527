import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";
import Introduction from "./Introduction";
import FindMe from "./FindMe";
import Story from "./Story";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Introduction />
        <Techstack />
        <Toolstack />
        <Github />
        <Story />
        <FindMe />
      </Container>
      <Particle />
    </Container>
  );
}

export default About;
