import React, { Suspense } from "react"
import { Container } from "react-bootstrap"
import Particle from "../Particle"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import AchievementCard from "./AchievementCard"
import commerce from "../../assets/achievementes/e-commerce.png"
import socialMedia from "../../assets/achievementes/social-media-app.png"
import database from "../../assets/achievementes/database.png"

function Achievements() {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Container fluid className="achievements-section">
          <h1 className="project-heading">
            My Recent <strong className="purple">Achievements </strong>
          </h1>
          <p style={{ color: "white" }}>Results of my work:</p>
          <Container fluid style={{ fontSize: "1.25rem" }}>
            <AchievementCard imgPath={commerce}>
              <p className="purple">
                For a headless commerce product where business owners can
                customize the users experience:
              </p>
              <p>
                • Optimized checkout page in a E-commerce website
                <strong> increasing revenues by $100k USD.</strong>
              </p>
              <p>
                • <strong>Planned and created a website for 40k users </strong>{" "}
                to update personal data, purchased products, change default
                payment methods.
              </p>
            </AchievementCard>
            <AchievementCard imgPath={socialMedia} imgPosition="right">
              <p className="purple">
                For a Social Media app with more than 1.2M users:
              </p>
              <p>
                • Improved user experience in a social media app avoiding
                waiting for 1-2 seconds.
              </p>
              <p>• Integrated Mercado Pago payment gateway.</p>
              <p>
                • <span className="boldFont">Implemented a new feature</span> to
                comment posts with media used more than{" "}
                <span className="boldFont">10.000 times</span> during the first
                month.
              </p>
            </AchievementCard>
            <AchievementCard imgPath={database}>
              <p className="purple">For a Fiancial website:</p>
              <p>
                •{" "}
                <span className="boldFont">
                  Reduced database queries response by 90%.
                </span>
              </p>
              <p>• Created 2 algorithms for data processing.</p>
              <p>• Designed and developed a CRM product.</p>
              <p>• Produced 8 pages with a high UI impact of graphical data.</p>
            </AchievementCard>
          </Container>
        </Container>
        <Particle />
      </Suspense>
    </div>
  )
}

export default Achievements
