import about from "../assets/about/about.png";
import avatar from "../assets/about/avatar.svg";
import engineering from "../assets/about/engineering.jpeg";
import path from "../assets/about/path.jpeg";
import programming from "../assets/about/programming.jpeg";
import thankyou from "../assets/about/thankyou.jpeg";
import visualBasic from "../assets/about/visual-basic.jpeg";
import windingRoad from "../assets/about/winding-road.jpeg";
import furniss from "../assets/projects/furniss.jpg";
import genius from "../assets/projects/genius.jpg";
import netflix from "../assets/projects/netflix-clone.jpg";
import lawyer from "../assets/projects/lawyer.jpg";
import commerce from "../assets/achievementes/e-commerce.png";
import socialMedia from "../assets/achievementes/social-media-app.png";
import database from "../assets/achievementes/database.png";
import flags from "../assets/flags.png";
import flags2x from "../assets/flags@2x.png";

const images = [];
const iframes = [];

const cacheImages = async (srcArray) => {
  const promises = await srcArray.map(
    (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
        images.push(img);
      })
  );

  await Promise.all(promises);
};

async function runPreload() {
  await cacheImages([
    avatar,
    path,
    visualBasic,
    windingRoad,
    engineering,
    programming,
    about,
    thankyou,
  ]);
  await cacheImages([furniss, genius, netflix, lawyer]);
  await cacheImages([commerce, socialMedia, database]);
  await cacheImages([flags, flags2x]);

  return images;
}

function stopPreload() {}

const cacheIframe = async (urlArray) => {
  // Doesn't work. Neither create a <Map /> component and reuse it.
  // Memoize react component and use that one?
  const promises = await urlArray.map(
    (src) =>
      new Promise((resolve, reject) => {
        const iframe = document.createElement("iframe");
        iframe.id = "1";
        iframe.title = "My location";
        iframe.key = "1";
        iframe.width = "100%";
        iframe.height = "600";
        // iframe.frameBorder="0"
        // iframe.scrolling="no"
        // iframe.marginHeight="0"
        // iframe.marginWidth="0"
        iframe.src = src;

        document.body.appendChild(iframe);
        iframe.onload = resolve();
        iframe.onerror = reject();
        iframes.push(iframe);
      })
  );

  await Promise.all(promises);
};

async function preloadIframe() {
  await cacheIframe([
    "https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Buenos%20aires,%20argentina+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed",
  ]);

  return iframes;
}

export { runPreload, stopPreload, preloadIframe };
