import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Container, Row } from "react-bootstrap";

function Github() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <Container style={{ paddingBottom: "20px" }}>
        <h1 className="project-heading" style={{ marginBottom: 0 }}>
          Days I <strong className="purple">Coded</strong>
        </h1>
        <span>(on Github)</span>
      </Container>
      <GitHubCalendar
        username="juansanchezlog"
        blockSize={15}
        blockMargin={5}
        color="#c084f5"
        fontSize={16}
      />
    </Row>
  );
}

export default Github;
