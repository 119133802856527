let connectionData;

export async function getConnectionData() {
  if (connectionData) return connectionData;

  const requests = [getConnectionData1, getConnectionData2, getConnectionData3];

  // TODO: It's fetching the same data twice. See why.
  for await (let req of requests) {
    connectionData = await req();
    if (connectionData) break;
  }

  return connectionData;
}

function getConnectionData1() {
  // Very good location precision for latitude and longitude, returns the city from where the connection was stablished./
  // Doesn't work with Brave
  // Works with Chrome and Chrome using incognito mode.
  let result;

  result = fetch("https://geolocation-db.com/json/")
    .then((res) => res.json())
    .catch((err) => {
      // handle error
      console.clear();
    });
  return result;
}

function getConnectionData2() {
  // Works with Brave, Chrome and Chrome using incognito mode.
  // The precision that offers as latitude and longitude are not precise. Doesn't return the city.
  return fetch(
    `http://api.ipstack.com/check?access_key=${process.env.REACT_APP_TRACKING_IPSTACK}`
    // {
    //   referrerPolicy: "unsafe_url",
    // }
  )
    .then((res) => res.json())
    .catch((err) => {
      console.clear();
      // handle error
    });
}

function getConnectionData3() {
  // Gets only the IP
  // Doesn't work with Brave
  // Works with Chrome and Chrome using incognito mode.
  return fetch("https://api.ipify.org/?format=json")
    .then((res) => res.json())
    .catch((data, status) => {
      console.clear();
      // handle error
    });
}
