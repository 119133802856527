import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import { useEffect } from "react";
import * as yup from "yup";

const PhoneInput = ({ countryCode, options }) => {
  useEffect(() => {
    const input = document.querySelector("#phone");

    // here, the index maps to the error code returned from getValidationError - see readme
    const errorMap = [
      "Please write in the international phone format",
      "Invalid country code",
      "Too short",
      "Too long",
      "Please write in the international phone format",
    ];

    // initialise plugin
    const iti = intlTelInput(input, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.17/js/utils.min.js",
      initialCountry: countryCode ?? "US",
      autoPlaceholder: "aggressive",
    });

    function isValidPhoneNumber(message) {
      return this.test("isValidCountry", message, function (value) {
        const { path, createError } = this;
        const itiErrorNumber = iti.getValidationError();
        const hasSpecialCharacters = value.match(
          // Only allows +()- and empty spaces
          /([~!@#$%^&*_}{|:"<>?=[\]\\;`',./`¡™£¢∞§¶•ªº–≠œ∑´®†¥¨ˆøπ“‘«åß∂ƒ©˙∆˚¬…æΩ≈ç√∫˜µ≤÷≥]+)/gim
        );
        const hasLetters = value.match(/([a-z])/gim);

        if (hasLetters) {
          return createError({
            path,
            message: "Phone numbers can't contain letters",
          });
        }

        if (hasSpecialCharacters) {
          return createError({
            path,
            message: `Can't have special characters like ${hasSpecialCharacters}`,
          });
        }

        if (itiErrorNumber) {
          return createError({
            path,
            message:
              itiErrorNumber === -99 ? errorMap[0] : errorMap[itiErrorNumber],
          });
        }

        return true;
      });
    }
    yup.addMethod(yup.mixed, "isValidPhoneNumber", isValidPhoneNumber);
  }, [countryCode]);

  return (
    <div style={{ marginBottom: "2em" }}>
      <input
        {...options}
        id="phone"
        style={{ paddingLeft: "3rem" }}
        className="form-control"
      />
    </div>
  );
};

export default PhoneInput;
