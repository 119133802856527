import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {
  getConnectionData,
  // connectionData,
} from "../../../utils/getConnectionData";
import PhoneInput from "./PhoneInput/PhoneInput.js";
import axios from "axios";
import SubmitButton from "./SubmitButton/SubmitButton";

const Form = ({ setEmailSent, setEmailLink, emailSent }) => {
  const [yupSchema, setYupSchema] = useState();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(yupSchema),
    mode: "onBlur",
  });
  const [connectionData, setConnectionData] = useState();

  useEffect(() => {
    setYupSchema(
      // I have to set the yup schema here because in PhoneInput.js I'm adding a custom validation "isValidPhoneNumber" and that validation uses "iti" validation which needs the input component to be rendered, so I need to define the schema after all the components are rendered. I can't create a validation.js file and put the logic there because I need the input component's to be rendered to use the "iti" validation.
      yup.object().shape({
        name: yup.string().required().min(3).max(50),
        email: yup.string().required().min(8).max(60).email(),
        company: yup.string().required().min(3).max(30),
        phone: yup.string().required().isValidPhoneNumber(),
        message: yup.string().required().min(50).max(500),
      })
    );

    setTimeout(() => {
      getData();
      async function getData() {
        const data = await getConnectionData();
        setConnectionData(data);
      }
    }, 2000);
  }, []);

  const onSubmit = () => {
    sendEmails();
    function sendEmails() {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/sendEmail`, {
          ...getValues(),
          connectionData,
        })
        .then((res) => {
          if (res.status === 200) setEmailSent(true);
          else setEmailSent(false);
        })
        .catch(() => setEmailSent(false));
    }
    const emailLink = (
      <span className="purple">
        <a
          href={`
            mailto:${process.env.REACT_APP_EMAIL}
            ?subject=${getValues("name")}'s inquery
            &body=%0D%0A
            Contact Data: %0D%0A
            Name: ${getValues("name")} %0D%0A
            Company Name: ${getValues("Company")} %0D%0A
            Email: ${getValues("email")} %0D%0A
            Phone: ${getValues("phone")} %0D%0A
            %0D%0A
            %0D%0A
            Message: %0D%0A
            ${getValues("message")}
          `}
          style={{
            color: "inherit",
            textDecoration: "inherit",
            textAlign: "justify",
            fontWeight: 600,
          }}
        >
          {process.env.REACT_APP_EMAIL}
        </a>
      </span>
    );
    setEmailLink(emailLink);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="contact__form w-100">
      <Row>
        <Col lg="6" className="form-group">
          <input
            placeholder="Name"
            {...register("name")}
            className="form-control"
          />
          {errors.name && (
            <p className="error-message">{errors.name?.message}</p>
          )}
          <input
            placeholder="Company"
            {...register("company")}
            className="form-control"
          />
          {errors.company && (
            <p className="error-message">{errors.company?.message}</p>
          )}
        </Col>
        <Col lg="6" className="form-group">
          <input
            placeholder="Email"
            {...register("email")}
            className="form-control"
          />
          {errors.email && (
            <p className="error-message">{errors.email?.message}</p>
          )}
          <PhoneInput
            options={register("phone")}
            countryCode={connectionData?.country_code}
          />
          {errors.phone && (
            <p className="error-message" id="phone-error-message">
              {errors.phone?.message}
            </p>
          )}
        </Col>
      </Row>
      <textarea
        placeholder="Describe what you need"
        {...register("message")}
        className="form-control"
      />
      <p className="error-message-textarea">{errors.message?.message}</p>
      <br />
      <div className="center-aligned">
        <SubmitButton isReadToSubmit={isValid} emailSent={emailSent} />
      </div>
    </form>
  );
};

export default Form;
