import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { IoLocationOutline } from "react-icons/io5";
import Form from "./Form/Form.js";
import SentMessage from "./Form/SentMessage/SentMessage";
import ErrorMessage from "./Form/ErrorMessage/ErrorMessage";

function Contact() {
  const [emailSent, setEmailSent] = useState();
  const [emailLink, setEmailLink] = useState();
  const [showSentMessage, setShowSentMessage] = useState();

  const formOrMessage = showSentMessage ? (
    <SentMessage emailLink={emailLink} />
  ) : emailSent === false ? (
    <ErrorMessage emailLink={emailLink} />
  ) : (
    <Form
      setEmailSent={setEmailSent}
      emailSent={emailSent}
      setEmailLink={setEmailLink}
    />
  );

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setShowSentMessage(true);
      }, 500);
    }
  }, [emailSent]);

  return (
    <Container fluid className="about-section">
      <Container>
        <Row className="mb-5 mt-3">
          <Col lg="5"></Col>
          <Col lg="7">
            <h1 className="project-heading">
              Contact <span className="purple">Me</span>
            </h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5" className="mb-5">
            <h3 className="color_sec py-4">
              <span className="purple">Get in touch</span>
            </h3>
            <address>
              <p>To discuss the best way to develop what you need.</p>
              <p>
                Please fill out the form and I will get back to you promptly
                regarding your request.
              </p>
              <br />
              <br />
            </address>
            <p style={{ color: "rgb(155 126 172)" }}>
              "Let's co-create the future together!"{" "}
            </p>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            {formOrMessage}
          </Col>
        </Row>
        <div className="address-map">
          <p className="center-aligned">
            <IoLocationOutline />
            <span style={{ marginLeft: "0.2rem" }}>
              Buenos Aires, Argentina
            </span>
          </p>
          {/* {console.log(
            "The next warnings are displayed due to a Google's error. Can't do anything about it."
          )} */}
          <iframe
            title="My location"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Buenos%20aires,%20argentina+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.gps.ie/car-satnav-gps/">GPS car tracker</a>
          </iframe>
        </div>
      </Container>
      <Particle />
    </Container>
  );
}

export default Contact;
