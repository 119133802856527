import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Fade, Collapse } from "react-bootstrap";
// import Fade from "react-bootstrap/Fade";

function AchievementCard({ imgPath, imgPosition, children }) {
  const [open, setOpen] = useState(true);

  return (
    <>
      {/* <Fade in={open}> Throws an error in the console */}
      {/* <Collapse in={open} dimension="width"> */}
      <Row
        className="justify-content-md-center achievement-card"
        style={{
          flexDirection: imgPosition === "right" && "row-reverse",
        }}
      >
        <Col md={3} className="achievement-card-row">
          <Card.Img
            variant="top"
            src={imgPath}
            alt="card-img"
            style={{
              display: "block",
              // maxWidth: "150%",
              // maxHeight: "80%",
              height: "auto",
              width: "auto",
            }}
          />
        </Col>
        <Col md={6} className="achievement-card-row">
          <Card.Body
            // className="achievement-card-row"
            style={{ textAlign: "justify" }}
          >
            {children}
          </Card.Body>
        </Col>
      </Row>
      {/* </Collapse> */}
      {/* </Fade> */}
    </>
  );
}

export default AchievementCard;
