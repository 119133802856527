import { Row } from "react-bootstrap"
import { MdOutlineSentimentVerySatisfied } from "react-icons/md"

const SentMessage = ({ emailLink }) => {
  return (
    <Row>
      <p>
        <span className="purple center-aligned" style={{ fontSize: "4rem" }}>
          <MdOutlineSentimentVerySatisfied />
          <span style={{ marginLeft: "1rem" }}>Email sent</span>
        </span>
      </p>
      <p>You should have received a confirmation email from me.</p>
      <p>Please check out your email inbox and your spam folder.</p>
      <br />
      <br />
      <br />
      <br />
      <p>If you didn't receive it please click this email {emailLink} </p>
      <p>to manually send the data you wrote.</p>
    </Row>
  )
}

export default SentMessage
