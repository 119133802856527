import { Row } from "react-bootstrap";

import { MdOutlineSentimentNeutral } from "react-icons/md";

const ErrorMessage = ({ emailLink }) => {
  return (
    <Row>
      <p>
        <span className="purple center-aligned" style={{ fontSize: "2rem" }}>
          <MdOutlineSentimentNeutral />
          <span style={{ marginLeft: "1rem" }}>
            Third party email service not working.
          </span>
        </span>
      </p>
      <p>This is a email service that doesn't belong to me.</p>
      <br />
      <br />
      <br />
      <br />
      <p>Please click this email {emailLink}</p>
      <p>to manually send the data you wrote.</p>
    </Row>
  );
};

export default ErrorMessage;
