import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import furniss from "../../assets/projects/furniss.jpg";
import genius from "../../assets/projects/genius.jpg";
import lawyer from "../../assets/projects/lawyer.jpg";
import netflix from "../../assets/projects/netflix-clone.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={furniss}
              isBlog={false}
              title="Marketplace"
              description="Features: Login & Registration, Payment integration, 
              purchased and sold products track, nice user interface an user experience."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={genius}
              isBlog={false}
              title="Improve memory"
              description="Mobile app to improve long-term memory and share knowledge."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={netflix}
              isBlog={false}
              title="Movies"
              description="Website that uses an API to show all the new movies availables and its data."
              link={`https://nottherealnetflix.com`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={lawyer}
              isBlog={false}
              title="Services"
              description="Fully responsive single page application to promote services online."
              link="https://monicabelenruiz.com/"
            />
          </Col>
        </Row>
      </Container>
      <Particle />
    </Container>
  );
}

export default Projects;
